$color-mint: #5BEDDD;
$color-gray1: #808080;
$color-gray2: #262626;
$color-message-input: #4D4D4D;
$color-message-my: #666666;
$color-message-his: #404040;
$color-black: #000000;
$color-new-nessages-label: #33CC66;

$no-activity-alert-progress-animation-time: 30s;
