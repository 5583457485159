@import "../../themes/vars";

.page-voice-chat {
  height: 100%;
  position: relative;

  .header {
    padding: 21rem 40rem 0 40rem;


  }

  .bottom-shadow {
    width: 100%;
    height: 300rem;
    position: fixed;
    bottom: 0;
    background-image: linear-gradient(to top, rgba(0,0,0,1) 0, rgba(0,0,0,1) 40%, rgba(0,0,0,0) 100%);
    pointer-events: none;
  }

  .hand-button-loader {
    position: absolute;
    bottom: 130rem;
    margin-left: -25rem;
  }
  .hand-button {
    position: absolute;
    bottom: 130rem;
    border-radius: 12rem;
    height: 54rem;
    font-size: 20rem;
    left: 50%;
    padding: 0 20rem 0 50rem;
    transform: translate(-50%, 0);
    text-align: right;
    line-height: 10rem;

    &.hand-up {
      background-color: $color-mint;
      color: $color-black;
    }

    &.hand-down {

      background-color: $color-gray2;
      color: $color-mint;
    }

    .icon {
      width: 30rem;
      height: 30rem;
      position: absolute;
      top: 12rem;
      left: 12rem;
      display: inline-block;

      &.hand-up {
        background: url(../../assets/images/hand-black.png) no-repeat;
        background-size: 30rem 30rem;
      }

      &.hand-down {
        background: url(../../assets/images/hand-mint.png) no-repeat;
        background-size: 30rem 30rem;
      }
    }
  }

  .exit-wrapper {
    width: 100%;
    font-size: 21rem;
    text-align: left;

    .exit {
      background-color: #000000;
      color: #ffffff;
      padding: 10rem 15rem 10rem 0;
      font-size: 20rem;
      font-family: "TT Norms Pro Normal";
      margin-left: 41rem;
      position: relative;
      text-align: left;

      &::before {
        position: absolute;
        top: 18rem;
        left: -45rem;
        content: ' ';
        width: 30rem;
        height: 30rem;
        background: url(../../assets/images/back-icon.png) no-repeat;
        background-position: bottom 0rem left 0rem;
        background-size: 30rem 30rem;
      }
    }
    button.profile {
      width: 42rem;
      height: 42rem;
      border-radius: 50%;
      background-color: red;
      float: right;
    }

    button.open-instruction {
      float: right;
      width: 30rem;
      height: 30rem;
      margin: 8rem 20rem 0 0;
      border-radius: 50%;
      background: url(../../assets/images/instruction.png) no-repeat;
      background-position: bottom 0rem left 0rem;
      background-size: 30rem 30rem;

    }

    button.open-terms {
      float: right;
      width: 30rem;
      height: 30rem;
      margin: 8rem 20rem 0 0;
      background: url(../../assets/images/file.png) no-repeat;
      background-position: bottom 0rem left 0rem;
      background-size: 30rem 30rem;

    }
  }

  .title-wrapper {
    position: absolute;
    top: 95rem;
    left: 0;
    background-color: black;
    width: 100%;

    &.chat-opening {
      transition: top 200ms ease-out;
    }

    .title {
      display: block;
      color: $color-mint;
      text-align: left;
      font-size: 26rem;
      margin: 6rem 40rem 0 40rem;
    }

    .count-users {
      color: $color-gray1;
      text-align: left;
      font-size: 21rem;
      margin-left: 40rem;
    }
  }

  .users-list {
    text-align: center;
    margin-top: 90rem;
    width: 100%;
    height: 100%;
    padding-bottom: 300rem;
    overflow-y: scroll;

    .user-wrapper {
      display: inline-block;
      position: relative;
      text-align: center;

      &.muted {

        &:after {
          content: ' ';
          position: absolute;
          width: 40rem;
          height: 40rem;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: url(../../assets/images/mic-off-red.png) no-repeat;
          background-size: contain;
        }

        .user {
          filter: blur(4rem);
        }

      }

      .name {
        display: block;
        font-size: 20rem;
        margin-top: 10rem;
        text-transform: lowercase;
        &:first-letter {
          text-transform: capitalize;
        }

        .is-speaker {
          display: inline-block;
          width: 20rem;
          height: 20rem;
          margin-bottom: -3rem;
          margin-left: 2rem;
          background: url(../../assets/images/speaker.png);
          background-size: 20rem 20rem;
        }

        .is-temp-speaker {
          display: inline-block;
          width: 20rem;
          height: 20rem;
          margin-bottom: -3rem;
          margin-left: 5rem;
          background: url(../../assets/images/temp-speaker.png);
          background-size: 20rem 20rem;
        }
      }
      .user {
        position: relative;
        display: inline-block;
        background-color: #cccccc;

        &.inactive {
          opacity: 0.5;
        }

        &.share {
          background: url(../../assets/images/plus.png) center center / 60rem 60rem no-repeat $color-gray2;
        }

        &.change-room {
          background: url(../../assets/images/refresh.png) center center / 40rem 40rem no-repeat $color-gray2;
        }

        &.size1 {
          width: 208rem;
          height: 208rem;
          border-radius: 50%;
          margin: 20rem 20rem 0 20rem;

          &.speaking::before {
            position: absolute;
            content: ' ';
            width: 214rem;
            height: 214rem;
            outline: 3rem solid $color-mint;
            top: -3rem;
            left: -3rem;
            border-radius: 50%;
          }

          &.mic-off::after {
            content: ' ';
            position: absolute;
            width: 40rem;
            height: 40rem;
            right: 10rem;
            bottom: 10rem;
            background-color: $color-gray2;
            border-radius: 50%;
            background: url(../../assets/images/mic-off.png) $color-gray2 no-repeat;
            background-position: bottom 9rem left 9rem;
            background-size: 22rem 22rem;
          }

          &.is-hand-up::after {
            content: ' ';
            position: absolute;
            width: 40rem;
            height: 40rem;
            right: 10rem;
            bottom: 10rem;
            border-radius: 50%;
            background: $color-gray2 url(../../assets/images/is-hand-up.png) no-repeat bottom 9rem left 11rem;
            background-size: 22rem 22rem;
          }
        }

        &.size2 {
          width: 156rem;
          height: 156rem;
          border-radius: 50%;
          margin: 30rem 30rem 0 30rem;

          &.speaking::before {
            position: absolute;
            content: ' ';
            width: 162rem;
            height: 162rem;
            outline: 3rem solid $color-mint;
            top: -3rem;
            left: -3rem;
            border-radius: 50%;
          }

          &.mic-off::after {
            content: ' ';
            position: absolute;
            width: 32rem;
            height: 32rem;
            right: 0rem;
            bottom: 0rem;
            background-color: $color-gray2;
            border-radius: 50%;
            background: url(../../assets/images/mic-off.png) $color-gray2 no-repeat;
            background-position: bottom 5rem left 5rem;
            background-size: 22rem 22rem;
          }

          &.is-hand-up::after {
            content: ' ';
            position: absolute;
            width: 32rem;
            height: 32rem;
            right: 8rem;
            bottom: 8rem;
            border-radius: 50%;
            background: url(../../assets/images/is-hand-up.png) $color-gray2 no-repeat;
            background-size: 32rem 32rem;
          }
        }

        &.size3 {
          width: 104rem;
          height: 104rem;
          border-radius: 50%;
          margin: 20rem 30rem 0 30rem;

          &.speaking::before {
            position: absolute;
            content: ' ';
            width: 110rem;
            height: 110rem;
            outline: 3rem solid $color-mint;
            top: -3rem;
            left: -3rem;
            border-radius: 50%;
          }

          &.mic-off::after {
            content: ' ';
            position: absolute;
            width: 32rem;
            height: 32rem;
            right: 0rem;
            bottom: 0rem;
            background-color: $color-gray2;
            border-radius: 50%;
            background: url(../../assets/images/mic-off.png) $color-gray2 no-repeat;
            background-position: bottom 5rem left 5rem;
            background-size: 22rem 22rem;
          }

          &.is-hand-up::after {
            content: ' ';
            position: absolute;
            width: 32rem;
            height: 32rem;
            right: 0rem;
            bottom: 0rem;
            background-color: $color-gray2;
            border-radius: 50%;
            background: url(../../assets/images/is-hand-up.png) $color-gray2 no-repeat;
            background-position: bottom 5rem left 5rem;
            background-size: 22rem 22rem;
          }
        }

        &.ava1 {
          background-image: url(/images/avatars/wt1.jpg);
          background-size: cover;
        }
        &.ava2 {
          background-image: url(/images/avatars/wt2.jpg);
          background-size: cover;
        }
        &.ava3 {
          background-image: url(/images/avatars/wt3.jpg);
          background-size: cover;
        }
        &.ava4 {
          background-image: url(/images/avatars/wt4.jpg);
          background-size: cover;
        }
        &.ava5 {
          background-image: url(/images/avatars/wt5.jpg);
          background-size: cover;
        }
      }
    }
  }

}
