@import "../../themes/vars";

.instruction-bg {
  position: absolute;
  background-color: #000000;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.instruction {
  z-index: 1001;
  position: absolute;
  background-color: $color-gray2;
  border-radius: 30rem;
  width: 80%;
  height: 75%;
  top: 15%;
  left: 10%;

  .title {
    margin-top: 40rem;
    font-size: 24rem;
    color: #ffffff;
    text-align: center;
  }

  .close {
    position: absolute;
    width: 60rem;
    height: 60rem;
    border-radius: 50%;
    margin-left: 100%;
    top: -80rem;
    left: -60rem;
    background: url(../../assets/images/close.png) $color-gray2 no-repeat;
    background-position: bottom 13rem left 14rem;
    background-size: 34rem 34rem;
  }

  .slide {
    height: 370rem;
    margin: 10rem 40rem 40rem 40rem;
    border-radius: 20rem;
    background-size: cover;
    border: 1rem solid $color-gray1;

    &.slide-0 {
      background: url(../../assets/images/instruction1.jpg);
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.slide-1 {
      background: url(../../assets/images/instruction2.jpg);
      background-size: cover;
      background-repeat: no-repeat;
    }

    &.slide-2 {
      background: url(../../assets/images/instruction3.jpg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }



  .text {
    font-size: 22rem;
    color: #ffffff;
    text-align: center;
    padding: 0 60rem;
    margin-top: 50rem;
    margin-bottom: 10rem;
    min-height: 125rem;
  }

  .bullets {
    margin-bottom: 30rem;
    .bullet {
      display: inline-block;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      background-color: $color-gray1;
      margin: 0 20rem;
      &.active {
        background-color: #ffffff;
      }
    }
  }

  .buttons {
    button {
      width: 140rem;
    }
  }

  .text-icon {
    display: inline-block;
    width: 24rem;
    height: 24rem;
    position: relative;
    top: 5rem;
    margin: 0 5rem;
    &.plus {
      background: url(../../assets/images/plus-instruction.png);
      background-size: contain;
    }

    &.refresh {
      background: url(../../assets/images/refresh-instruction.png);
      background-size: contain;
    }
  }

}
