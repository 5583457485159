@import "./themes/vars";

.App {
    text-align: center;
    height: 100%;
    position: relative;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html {
    height: 100%;
}

body {
    background-color: #000000;
    color: #ffffff;
    height: 100%;
}

#root {
    height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.loader-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25rem;
    display: inline-block;
    background: url(/assets/images/loader.png) ;
    background-size: cover;
    width: 50rem;
    height: 50rem;
    animation: rotate-loader 3s linear infinite;
}

@keyframes rotate-loader {
    100% {transform: rotate(359deg);}
}
.loader {
    display: inline-block;
    background: url(/assets/images/loader.png) ;
    background-size: cover;
    width: 50rem;
    height: 50rem;
    animation: rotate-loader 3s linear infinite;

}

/*==============*/
button {
    border: none;
    padding: 0;
}

.button-mint {
    color: #000000;
    background-color: $color-mint;
}

.button-inline {
    display: inline-block;
    margin: 0 5rem;
    border-radius: 12rem;
    height: 54rem;
    font-size: 20rem;
    font-weight: bold;
}

.button-wide {
    height: 54rem;
    text-align: center;
    width: 100%;
    margin: 12rem 0;
    font-size: 20rem;
    font-weight: bold;
    border-radius: 12rem;
}

.button-inline {
    display: inline-block;
    margin: 0 5rem;
}

.resize-size {
    position: absolute;
    right: 10rem;
    top: 10rem;
}
