@import "../../themes/vars";

html[album="1"] .album-popup-bg {
  display: block;
}

html[album="1"] .album-popup {
  display: block;
}

.album-popup-bg {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color-black;
  opacity: 0.7;
  z-index: 9998;
}

.album-popup {
  display: none;
  position: absolute;
  width: 60%;
  left: 20%;
  border-radius: 24rem;
  background-color: $color-gray2;
  margin: 0;
  padding: 90rem 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 9999;

  .rotate-icon {
    width: 80rem;
    height: 80rem;
    background: url(../../assets/images/rotate-icon.png) $color-gray2 no-repeat;
    //background-position: bottom 13rem left 14rem;
    background-size: cover;
    display: inline-block;
  }

  .text {
    width: auto;
    margin: 35rem 40rem 0 40rem;
    font-size: 35rem;
    text-align: center;
    width: 70%;
    display: inline-block;

  }



}
