@import "../../themes/vars";

.toast {
  position: absolute;
  bottom: 140rem;
  left: 0rem;
  max-width: 80%;
  min-width: 20%;
  background-color: $color-gray2;
  border-radius: 8rem;
  transform: translate(-50%, 0);
  padding: 10rem 10rem;
  margin-left: 50%;
  box-shadow: 0 0 20rem $color-black;
  font-size: 19rem;

  &.hide {
    opacity: 0;
    transition: opacity 0.5s linear
  }

  span {
    color: $color-mint;
  }

}
