@import "../../themes/vars";

.popup-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $color-black;
  opacity: 0.7;
}

.popup {
  position: absolute;
  width: 80%;

  top: 100rem;
  border-radius: 24rem;
  left: 10%;
  background-color: $color-gray2;
  margin: 0;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  .text {
    width: auto;
    margin: 35rem 40rem 0 40rem;
    font-size: 20rem;
  }

  .countdown {

    height: 3rem;
    width: 60%;
    margin: 20rem 0 20rem 20%;
    background-color: $color-mint;
    &.run {
      -webkit-transition: width $no-activity-alert-progress-animation-time linear;
      -moz-transition: width $no-activity-alert-progress-animation-time linear;
      -o-transition: width $no-activity-alert-progress-animation-time linear;
      transition: width $no-activity-alert-progress-animation-time linear;
      width:0%;
    }
  }

  .buttons {
    width: auto;
    margin: 20rem 20rem 40rem 20rem;
    .button {
      min-width: 150rem;
    }
  }

}
