@import "../../themes/vars";


.page-terms {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  overflow-y: scroll;

  .header {
    width: 100%;
    height: 80rem;
    position: fixed;
    background: black;
    .title {

      font-size: 20rem;
      position: relative;
      margin-left: 80rem;
      margin-bottom: 36rem;
      text-align: left;
      top: 30rem;

      .back {
        position: absolute;
        top: -2rem;
        left: -45rem;
        content: ' ';
        width: 30rem;
        height: 30rem;
        background: url(../../assets/images/back-icon.png) no-repeat;
        background-position: bottom 0rem left 0rem;
        background-size: 30rem 30rem;
      }
    }
  }

  .text {
    font-size: 20rem;
    text-align: left;
    padding: 80rem 40rem 0 40rem;

    ol, ul {
      padding-left: 20rem;
      li {
        margin-bottom: 20rem;
      }
    }
  }
}










