@import "../../themes/vars";

html[too-wide="1"] .hide-on-too-wide {
  display: none !important;
}

.page-connect {
  height: 100%;
  overflow-y: scroll;

  .change-room-popup {
    background-color: $color-black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    text-align: center;

    .loader {
      position: absolute;
      top: 48%;
      left: 50%;
      margin-left: -25rem;
    }

    .text {
      position: absolute;
      top: 56%;
      text-align: center;
      width: 100%;
      color: #ffffff;
      font-size: 20rem;
    }
  }

  .logo {
    margin-top: 12rem;
    img {
      height: 14rem;
    }
  }

  .icon {
    margin-top: 100rem;
    img {
      height: 40rem;
    }
  }

  .title {
    color: $color-mint;
    font-size: 26rem;
    margin-top: 25rem;
  }

  .subtitle {
    margin-top: 95rem;
    color: #ffffff;
    font-size: 20rem;
    line-height: 25rem;
    padding: 0 120rem;
  }


  .form{
    text-align: center;
    border: 1rem solid #404040;
    width: 80%;
    border-radius: 24rem;
    height: 240rem;
    margin-left: 10%;
    margin-top: 60rem;

    .input-label {
      font-size: 18rem;
      text-align: left;
      width: 80%;
      display: inline-block;
      margin-top: 20rem;
    }

    input#code {
      display: inline-block;
      background-color: $color-gray2;
      border: 1rem solid #404040;
      height: 55rem;
      border-radius: 10rem;
      text-align: center;
      color: #ffffff;
      width: 80%;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      margin-top: 20rem;
      margin-bottom: 10rem;
      font-size: 30rem;
      letter-spacing: 4rem;
      font-family: "TT Norms Pro Normal";
      outline: none;
      &.wrong {
        border: 1rem solid red;
      }
    }

    input#code:focus {
      border: 1rem solid $color-gray1;
    }


    .loader {
      margin-top: 10rem;
    }

    .error {
      color: red;
      font-size: 20rem;
      text-align: center;
    }

    button.enter {
      width: 80%;
      border-radius: 10rem;
    }

    button.enter:disabled {
      background-color: $color-gray1;
    }
  }

  .open-instruction {
    position: absolute;
    bottom: 130rem;
    text-align: center;
    width: 100%;

    label {
      display: block;
      padding-left: 15rem;
      text-indent: -15rem;
      font-size: 20rem;

      input {
        width: 22rem;
        height: 22rem;
        padding: 0;
        margin: 0 5rem 0 0;
        vertical-align: bottom;
        position: relative;
        top: -1rem;
      }
    }

  }

  .rooms-title {
    font-size: 16rem;
    color: #ffffff;
    text-align: left;
    margin: 40rem 0 10rem 10%;
  }

  .rooms-list {
    text-align: center;
    .room {
      position: relative;
      display: inline-block;
      background-color: $color-gray2;
      width: 80%;
      border-radius: 24rem;
      text-align: left;
      margin-bottom: 10rem;

      &.speaker::after {
        content: ' ';
        position: absolute;
        width: 30rem;
        height: 30rem;
        right: 30rem;
        top: 30rem;
        background: url(../../assets/images/speaker-big.png);
        background-size: 30rem 30rem;
      }

      .inner-box {
        margin: 30rem;
      }
      .room-title {
        font-size: 24rem;
        color: $color-mint;
        margin-bottom: 6rem;
      }
      .room-subtitle {
        font-size: 20rem;
        .number {
          color: #ffffff;
        }
        .users {
          color: $color-gray1;
          margin-left: 20rem;
        }
      }
      .speakers {
        margin-top: 20rem;
        font-size: 20rem;
        color: $color-gray1;
      }

    }
  }


}










