@import "../../themes/vars";

@keyframes open-chat {
  0% {
    bottom: -785rem;
  }
  100% {
    bottom: 0;
  }
}

.text-chat {
  position: absolute;
  width: 100%;
  bottom: -785rem;

  &.opening {
    transition: bottom 200ms ease-out;
  }


  .header {
    height: 105rem;
    background-color: $color-gray2;
    border-radius: 42rem 42rem 0 0;
    padding: 21rem 21rem;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: 3rem solid #000000;
    position: relative;

    .swipe {
      background-color: $color-gray1;
      height: 6rem;
      width: 8%;
      border-radius: 3rem;
      position: absolute;
      top: 15rem;
      left: 46%;
    }

    button {
      width: 62rem;
      height: 62rem;
      border-radius: 50%;
      background-color: #000000;
      margin-right: 21rem;
      float: left;

      &.pressed {
        background-color: $color-mint;
      }
      &.mic-on {
        background: url(../../assets/images/mic-on.png) $color-mint no-repeat;
        background-position: bottom 13rem left 14rem;
        background-size: 34rem 34rem;
      }
      &.mic-off {
        background: url(../../assets/images/mic-off.png) $color-black no-repeat;
        background-position: bottom 13rem left 14rem;
        background-size: 34rem 34rem;
      }

      &.sound-on {
        background: url(../../assets/images/sound-on.png) $color-mint no-repeat;
        background-position: bottom 14rem left 15rem;
        background-size: 34rem 34rem;
      }
      &.sound-off {
        background: url(../../assets/images/sound-off.png) $color-black no-repeat;
        background-position: bottom 14rem left 14rem;
        background-size: 34rem 34rem;
      }

      &.chat {
        float: right;
        position: relative;
        background: url(../../assets/images/chat.png) $color-black no-repeat;
        background-position: bottom 15rem left 16rem;
        background-size: 30rem 30rem;
        margin-right: 0;

        .has-new-message {
          position: absolute;
          width: 22rem;
          height: 22rem;
          background-color: $color-new-nessages-label;
          border-radius: 50%;
          color: white;
          font-size: 14rem;
          line-height: 24rem;
          left: 1rem;
          top: 1rem;
        }
      }
      &.last-speaker {
        float: right;
        position: relative;
        background-color: red;
        margin-right: 0;
        margin-left: 14rem;

        &.speaking::before {
          content: ' ';
          position: absolute;
          width: 67rem;
          height: 67rem;
          top: -6rem;
          left: -6rem;
          border-radius: 50%;
          border: 3rem solid $color-mint;
        }
      }
    }
  }

  .main {
    height: 680rem;
    background-color: $color-gray2;
    overflow: scroll;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-bottom: 3rem solid #000000;
  }

  .footer {
    height: 105rem !important;
    padding: 21rem 21rem !important;
    background-color: $color-gray2;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;

    .message-input {
      display: inline-block;
      font-size: 20rem;
      height: 46rem;
      float: left;
      width: 100%;
      background-color: $color-message-input;
      border-radius: 23rem;
      border: none;
      color: #ffffff;
      padding-left: 20rem;
      font-family: "TT Norms Pro Normal";
      //background-color: ;

      &:focus-visible {
        outline: none;
      }
    }

    .send-message-td {
      width: 63rem;
    }

    .send-message {
      width: 46rem;
      height: 46rem;
      float: right;
      border-radius: 50%;
      background-color: $color-mint;
      margin-left: 40rem;
      background: url(../../assets/images/send-icon.png) $color-mint no-repeat;
      background-position: bottom 5rem left 7rem;
      background-size: 34rem 34rem;

    }


  }

  .message-list {
    padding: 21rem 21rem 0 80rem;
    .message {
      border-radius: 32rem;
      padding: 16rem 21rem;
      margin-bottom: 12rem;
      text-align: left;
      font-size: 24rem;
      line-height: 30rem;
      width: 70%;
      overflow-wrap: anywhere;

      &.sending {
        opacity: 25%;
      }

      .author {
        color: $color-mint;
      }
    }
    .message-series-his {
      width: 100%;
      margin-bottom: 12rem;
      display: inline-block;

      .message {
        background-color: $color-message-his;
        float: left;
        &:last-child {
          position: relative;
          border-bottom-left-radius: 0;
        }

        .avatar {
          position: absolute;
          width: 42rem;
          height: 42rem;
          bottom: 0;
          left: -58rem;
          border-radius: 50%;
          background-color: #61dafb;
        }
      }
    }

    .message-series-my {
      width: 100%;
      margin-bottom: 12rem;
      display: inline-block;
      .message {
        background-color: $color-message-my;
        float: right;
        &:last-child {
          border-bottom-right-radius: 0;
        }
      }
    }
  }

}
